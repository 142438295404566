window.CustomerShowcase = (customerShowcaseNonce, dealerDataNonce) => {
    return {
        selectedRegion: 'North America',
        globeState: 0,
        groupNodes: [],
        accordionOpen: null,
        swiper: null,
        init() {
            const innerBlocks = this.$refs.innerBlocks.querySelector(
                '.acf-innerblocks-container'
            );
            this.groupNodes = Array.from(innerBlocks.children).filter((child) =>
                child.classList.contains('wp-block-group')
            );

            if (this.groupNodes.length === 0) return;

            this.groupNodes.forEach((group) => {
                let loopIndex = this.groupNodes.indexOf(group);
                this.$refs[`regionAccordion${loopIndex}`].innerHTML =
                    group.innerHTML;
                group.style.display = 'none';
            });
            this.groupNodes[0].style.display = 'block';

            setTimeout(() => {
				this.swiper = new Swiper(this.$refs.dealerSwiper, {
					effect: 'fade',
                    slidesPerView: 1,
                    spaceBetween: 30,
                    loop: true,
					autoplay: {
						delay: 7000,
					},
                    width: 250,
					navigation: {
						nextEl: this.$refs.swiperNext,
						prevEl: this.$refs.swiperPrev,
					},
					centeredSlides: true,
                    a11y: true,
				});
			}, 0);
        },
        openAccordion(index) {
            if (this.accordionOpen === index) {
                this.accordionOpen = null;
                return;
            }
            this.accordionOpen = index;
        },
        getAccordionState(index) {
            if(this.accordionOpen === index) {
             return 'fill-black';
            }
        },
        getRegionState(region) {
            if (region === this.selectedRegion) {
                return '!bg-black !text-xapt-orange';
            }
        },
        changeRegion(regionId, sectionNumber) {

            if (this.groupNodes.length === 0) return;

            this.groupNodes.forEach((group) => {
                group.style.display = 'none';
            });

            const group = this.groupNodes[sectionNumber];
            group.style.display = 'block';
            this.selectedRegion = regionId;
            this.globeState = sectionNumber;
        }
    };
};

window.DealerContainer = (activeDealer) => {
    return {
        activeDealer: activeDealer,
        changeDealer(dealer) {
            this.activeDealer = dealer;
        },
        getActiveDealer(dealerId) {
            if (dealerId === this.activeDealer) {
                return 'border-white bg-black';
            }
        }
    };
}

