
window.Testimonials = () => {
	return {
		swiper: null,
		init() {
			setTimeout(() => {
				this.swiper = new Swiper(this.$refs.swiperContainer, {
					loop: true,
					spaceBetween: 100,
					pagination: { el: '.swiper-pagination', clickable: true },
					effect: 'fade',
					slidesPerView: 1,
					autoplay: {
						delay: 7000,
					},
					navigation: {
						nextEl: this.$refs.swiperNext,
						prevEl: this.$refs.swiperPrev,
					},
					centeredSlides: true,
					a11y: true,
				});
			}, 0);
			
		},
	};
};
