window.DealerDisplay = () => {
	return {
		swiper: null,
        init() {
            setTimeout(() => {
				this.swiper = new Swiper(this.$refs.dealerSwiper, {
					effect: 'fade',
                    slidesPerView: 1,
                    spaceBetween: 30,
                    loop: true,
					autoplay: {
						delay: 7000,
					},
                    width: 250,
					navigation: {
						nextEl: this.$refs.swiperNext,
						prevEl: this.$refs.swiperPrev,
					},
					centeredSlides: true,
					a11y: true,
				});
			}, 0);
        },
	};
};
